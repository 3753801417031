/**
 * Flow
 * 1. Visit `/login`
 * 2. Enter email at `/login` (this route is much like an auth "router")
 *     - Post to `/v1/users?embed=auth_provider&email=user@example.com`
 *     - Redirect user based on their auth provider: SSO or SYSTEM
 *         - NOTE that there is a _special case_ for PZ as follows...
 *             - First redirect to `console.partnerize.com/sso/authorize/pz` to see if the last session is still good
 *             - If the previous cookie is still valid follow steps in 3.1 (PZ Flow) for reading the cookie and storing in cache
 *             - If there is no cookie then redirect to SSO link provided from the auth provider
 * 3. Redirect to auth provider flow
 *     - 3.0 System auth (local)
 *         - `/login/auth`
 *     - 3.1 SSO (on redirect back into app from SSO provider)
 *         - PZ flow
 *             - PZ redirects (links currently) to `protect.partnerize.com/sso/authorize/pz` with a cookie
 *                 - Partnerize does not currently automatically redirect based on auth to their system. Instead, a
 *                   user must authenticate, then via PZ's navigation, link back to BV 😬
 *                 - A cookie will be dropped on `console.partnerize.com` when the user authenticates
 *                 - 👀 PAY ATTENTION...the URL `protect.partnerize.com/sso/authorize/pz` points to the same IP address as
 *                   `console.brandverity.com/sso/authorize/bv` via DNS settings AND because of this we can read the cookie
 *                   that was originally dropped on `console.partnerize.com` when the user authenticated.
 *                     - This little slight of hand gives us the ability to read the first party cookie and store it in the
 *                       cache for retrieval later on in the authentication flow.
 *             - Read the cookie on `protect.partnerize.com/sso/authorize/pz`
 *             - Hash the value of the encrypted cookie and store in Redis
 *             - Redirect back to local route with hash `console.brandverity.com/sso/authorize/pz?ref=a8424cda38ef118579f03c63a7`
 *                 - IMPORTANT: notice that this is NOT `protect.partnerize.com` now but `console.brandverity.com`
 *                   despite the paths being the same. This is done via DNS as mentioned previously. The key difference
 *                   besides the base URL is the Search Param `ref`. If the `ref` is present and a value is in the cache
 *                   THEN we can move on to the next step.
 *             - Get and validate user from cache and post to `/ssologin` to authenticate the user
 *             - (depending on result move on to #4 or back #1)
 * 4. Create local Remix session for the user
 * 5. Redirect to client selection page at `/clients`
 */

import { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node';
import {
	Form, json, redirect, useLoaderData,
} from '@remix-run/react';
import { useProcessing } from '~/hooks';
import AuthLayout from '../compositions/AuthLayout';
import OverlayContent from '~/components/OverlayContent/OverlayContent';
import Input from '~/components/Input';
import styles from '~/styles';
import { commitSession, getOrCreateEmptySession, getSession } from '~/services/sessions.server';
import getAuthProvider from '~/routes/api/console/auth/authProvider';
import { AppConfig } from '~/config/app.server';
import Alert from '~/components/Alert';
import { report } from '~/utilities/qol';

export async function loader({ request }: LoaderFunctionArgs) {
	const session = await getOrCreateEmptySession(request.headers.get('Cookie'));

	if (session.has('user')) {
		return redirect('/');
	}

	const { searchParams } = new URL(request.url);

	// There is not way to flash a message to users coming from PZ SSO so we detect here
	const ssoFail = searchParams.get('sso') === 'fail';

	return json({ ssoFail });
}

export default function Login() {
	const { ssoFail } = useLoaderData<typeof loader>();
	const { isProcessing } = useProcessing();

	return (
		<AuthLayout>
			{isProcessing && <OverlayContent />}

			<Form
				method="POST"
				className="uic-flex uic-flex-col uic-gap-20"
			>
				{ssoFail && (
					<Alert level="warning">SSO login failed</Alert>
				)}

				<h1 className="uic-headline-lg">Login</h1>

				<Input
					id="email"
					labelText="Email"
					type="email"
					inlineLabelWidth={50}
					required
					data-testid="TestId__EMAIL"
				/>

				<button
					type="submit"
					className={`${styles.PrimaryButton} uic-justify-center`}
					data-testid="TestId__LOGIN"
				>Login
				</button>
			</Form>
		</AuthLayout>
	);
}

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData();
	const session = await getSession(request.headers.get('Cookie'));

	try {
		const authProvider = await getAuthProvider(`${formData.get('email')}`);

		// User has an `authProvider`
		if (authProvider) {
			if (authProvider.identifier === 'partnerize') {
				// Vizzini said go back to the beginning, well...I'm (going) back at the beginning!
				// In other words, for PZ SSO users we _first_ try to see if the session is _still_ good before forwarding
				// on to the `login_url`. We do this as a convenience to the user just in case their PZ session is still
				// good. Since `PZ_SSO_ENTRY_URL` lives on the `partnerize` domain but points to our application we can
				// look for the cookie and reauthenticate if found. If it isn't found we look at the `next` Search Param and
				// forward the user on to the provider URL.
				return redirect(`${AppConfig.PZ_SSO_ENTRY_URL}?next=${authProvider.login_url}`, {
					headers: {
						'Set-Cookie': await commitSession(session),
					},
				});
			}

			return redirect(`${authProvider.login_url}`, {
				headers: {
					'Set-Cookie': await commitSession(session),
				},
			});
		}

		// System Auth
		return redirect(`/login/auth?email=${formData.get('email')}`, {
			headers: {
				'Set-Cookie': await commitSession(session),
			},
		});
	} catch (error: unknown) {
		report.error('There was a problem getting auth provider', error);
		session.flash('GLOBAL_MESSAGE', 'FAIL: There was a problem logging in!');

		return redirect('/login', {
			status: 400,
			headers: {
				'Set-Cookie': await commitSession(session),
			},
		});
	}
}
